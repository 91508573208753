@import "./color.scss";
@import "./utils.scss";
@import "./font.scss";

.button-secondary {
  border-color: $primary-hale;
  color: $primary-hale;
  &:hover {
    border-color: $primary_tonic;
    color: $primary_tonic;
  }
}

.button-primary {
  background-color: $primary_tonic;
  font-size: 18px;
}

body {
  padding-right: 0 !important;
}

.scroll,
body {
  padding-right: 4rem;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px $neutrals-grey-medium;
    -webkit-box-shadow: inset 0 0 6px $neutrals-grey-medium;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    // background-color: $primary-hale;
    -webkit-box-shadow: inset 0 0 13px $primary-hale;
    box-shadow: inset 0 0 13px $primary-hale;
  }
}

.hidden {
  display: none;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-lower {
  text-transform: lowercase !important;
}

.horizonal_rule {
  color: $primary-hale;
  border-top: 1.5px solid $primary-hale;
  width: 100%;
  margin-top: 0;
}

.cursor-pointer {
  cursor: pointer;
}
