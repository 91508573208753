@import "../../styles/index.scss";
header {
  .MuiGrid-container {
    .MuiGrid-root {
      margin-top: 16px;
    }
  }

  .header_container {
    @media only screen and (max-width: 1250px) {
      justify-content: center;
    }
  }
  .signin {
    .MuiButton-label {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */

      /* Colour / FFF */

      color: #ffffff;
    }
    @media only screen and (max-width: 1250px) {
      margin-bottom: 16px;
    }
  }

  .makeStyles-searchIcon-9 {
    input {
      font-weight: 600;
    }
  }
}
.menuanchor {
  text-decoration: none;
  color: inherit;
}
.id-check-awaiting {
  margin-left: 6px;
  color: #D80606;
}

.navigation {
  .avatar {
    background: #233645;
  }
  .navlinks {
    // margin-left: 40px;
    padding-top: 7px;
    padding-bottom: 16px;
    margin-left: 42px;
    cursor: pointer;
    
    .link {
      text-decoration: none;
      cursor: pointer;

      font-weight: 600;
      font-size: 16px;
      line-height: 32px;
      /* identical to box height, or 200% */

      /* Colour / FFF */

      color: $white;
    }

    .arrow_down {
      padding: 4px 0 0 6px;
      cursor: pointer;
    }
    .id-check-warning {
      padding: 6px 0px 0px 6px;
    }
  }
  
  .marginLeft {
    margin-left: 32px;
  }
  menu {
    overflow: visible;
  }

  menu::before {
    content: '""';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    margin-top: -9px;
    display: block;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid red;
  }

  .paddingTop {
    padding-top: 32px;
  }

  .logo_text {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    /* identical to box height */

    /* Colour / FFF */

    color: $white;
  }
}

@media only screen and (max-width: 1445px) and  (min-width: 700px) {
  .navigation {
    .navlinks{
       margin-left: 20px;
      }
    } 
    .search-box-custom{
      margin-left: 10px;
      width: 418px;
     }  
}
@media only screen and (max-width: 1343px) and  (min-width: 1250px) {
     .search-box-custom{
     width: 342px;
    } 
}