$primary-hale: #014c6b;
$primary-tonic: #03a58f;
$primary-hale-dark: #003d56;
$neutrals-grey-light: #f4f4f4;
$neutrals-grey-medium: #e2e2e2;
$neutrals-grey-dark: #747474;
$light_grey: #fafafa;

$warning: #de5000;
$error: #d80606;

$black: #000000;
$white: #ffffff;

$neutral-black: #262525;

.color-neutrals-grey-dark {
  color: $neutrals-grey-dark;
}

.color-error {
  color: $error;
}

.color-warning {
  color: $warning;
}
