@import "../../../../../styles/index.scss";

.view_images {
  height: 100%;

  &-dialog {
    .MuiPaper-rounded {
      // max-width: 72%;
      height: 90vh;
      width: 90vw;
      max-height: 900px;
    }

    .custom-dialog-content {
      margin-top: 34px;
      margin-bottom: 23px;
      padding-left: 0;

      margin-right: 0;
      min-width: auto !important;

      .content-container {
        height: 100%;
        max-height: 100%;
        padding-right: 0 !important;
      }
    }
  }

  .container {
    border-radius: 1rem;
    width: 70%;
    top: 0%;
    height: 22%;
    margin-left: 15%;
    margin-right: 15%;
    overflow-y: hidden;
    overflow-x: hidden;
  }

  .image_carsolue {
    flex-direction: column;
    display: flex;
    //align-items: center;
    width: 100%;

    .image_container {
      margin: auto;
      // height: 100%;
      display: flex;
      overflow: hidden;

      div {
        margin: auto;
        display: block;
        height: 100%;
      }
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }

    p:nth-child(2) {
      text-align: left;
      color: #014c6b;
    }

    p.imageTitle {
      font-weight: 500;
      font-size: 30px;
      line-height: 140%;
      margin-top: 0;
      margin-bottom: 13px;
      color: $primary-hale;
      text-align: center;
    }

    .image_numbers {
      font-weight: 500;
      font-size: 20px;
      line-height: 140%;
      margin-top: 27px;
      margin-bottom: 0;
      color: $primary-hale;
      text-align: center;
    }
  }

  .closeIcon {
    align-self: right;
    justify-content: right;
    display: flex;
    margin: "5rem";
  }

  .imageTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 140%;
    width: 100%;
    justify-content: center;
    display: flex;
    /* or 22px */

    color: #003d56;
  }

  .paper {
    width: 50%;
    padding: 0;
    margin: 0;
    overflow-y: hidden;
  }

  .prevNextContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    // margin-top: 40px;
  }

  .prev {
    align-self: center;
    cursor: pointer;
    width: 2rem;
    height: 2rem;
  }

  .next {
    align-self: center;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
  }
}