@import "../../index.scss";

.w-subscription-details-container{
    display: flex;
    flex-direction: column;
    padding: 24px 18px;
    gap: 21px;
    border: 4px solid #014C6B;
    border-radius: 12px;
    margin-bottom: 1.25rem;
    color: #014C6B;
    .w-subscription-details-inline{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .w-subscription-details-label{
        font-weight: 500;
        font-size: 1.25rem;
    }
    .w-subscription-details-productName{
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.4;
    }
}

.w-subscription-history-container{
        margin-top: 1.25rem;
        color: #014C6B;
    .w-subscription-history-heading{
        font-weight: 700;
        font-size: 1.375rem;
        margin-bottom: 1.375rem;
    }
    .w-subscription-history-tableheading{
        padding: 12px 0px !important;
        background-color: #F2F6F7;
        color: #014C6B !important;
    }
    .class_header{
        color: #014C6B;
        font-size: 1rem; 
        font-weight: 700;
    }
    .class1{
        color: #0C161F;
        font-size:0.875rem;
        font-weight: "400";
    }

    .class2{
        color: #0C161F;
        font-size:0.875rem;
        font-weight: "400";
        background-color: #F3F5F5;
    }
    
}

