// Margin

.mt-0 {
  margin-top: 0;
}

.mt-10 {
  margin-top: 0.625rem;
}

.mt-16 {
  margin-top: 1rem;
}

.mb-16 {
  margin-bottom: 1rem;
}

.mt-20 {
  margin-top: 1.25rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mr-10 {
  margin-right: 0.625rem;
}
.mr-36 {
  margin-right: 2.25rem;
}

.mb-10 {
  margin-bottom: 0.625rem;
}

.mb-20 {
  margin-bottom: 1.25rem;
}

// Layout

.row {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.col-gap-12 {
  column-gap: 0.75rem !important;
}

.color-primary-hale {
  color: $primary-hale;
}
.color-primary-hale:hover {
  color: rgb(2, 115, 100);
}

.rotate-180 {
  transform: rotate(180deg);
}

.w-full {
  width: 100%;
}

.min-h-full {
  min-height: 100vh;
}
