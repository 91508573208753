@import "../../styles/index.scss";

.crp {
  &-dashboard {
    &-container {
      margin-top: 4.375rem;
      border: 1px solid rgba($color: $black, $alpha: 0.2);
      border-radius: 12px;

      padding: 3.25rem;

      display: flex;
      flex-direction: column;
      row-gap: 3.25rem;

      background-color: $white;
    }

    &-title {
      font-weight: 500;
      font-size: 1.875rem;
      line-height: 1.875rem;
      color: $primary-hale;
    }
  }
}

.multi-line-info {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  margin-bottom: 0.5rem;

  span {
    min-width: 4.5rem;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
